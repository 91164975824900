<template>
    <div class="custom-filter" @mouseenter="isDropdownOpen = true" @mouseleave="isDropdownOpen = false">
        <ul class="dropdown-options">
            <li 
                v-for="option in options" 
                :key="option.value" 
                @click="setFilter(option.value)" 
                :class="{ selected: selectedValue === option.value }"
            >
                {{ option.label }}
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    name: "CustomFilter",
    data() {
        return {
            selectedValue: "all",
            isDropdownOpen: false,
            params: null,
            options: [
                { value: "all", label: "All" },
                { value: "open", label: "Open" },
                { value: "closed", label: "Closed" },
            ],
        };
    },
    methods: {
        isFilterActive() {
            return this.selectedValue !== "all";
        },
        doesFilterPass(params) {
            const value = params.data.Status;
            if (this.selectedValue === "open") return value !== "Closed";
            if (this.selectedValue === "closed") return value === "Closed";
            return true;
        },
        getModel() {
            return this.isFilterActive() ? { value: this.selectedValue } : null;
        },
        setModel(model) {
            this.selectedValue = model ? model.value : "all";
        },
        onFilterChanged() {
            this.params.filterChangedCallback();
        },
        onParentModelChanged(parentModel) {
            this.setModel(parentModel);
        },
        setFilter(value) {
            this.selectedValue = value;
            this.onFilterChanged();
        },
    },
    created() {
        if (this.params?.filterParams?.initialModel) {
            this.setModel(this.params.filterParams.initialModel);
        }
        if (this.params?.filterChangedCallback) {
            this.params.filterChangedCallback();
        }
    },
});
</script>

<style scoped>
.custom-filter {
    position: relative;
    display: inline-block;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 120px;
    cursor: pointer;
    text-align: center;
}

.dropdown-options {
    position: relative; /* Keep it positioned inside the container */
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown-options li {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    min-height: 40px; /* Ensures consistent height */
    display: flex;
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
}

.dropdown-options li:hover,
.dropdown-options .selected {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

</style>
